import QrCodeSvg from 'qrcode-svg'
import Chunk from 'lodash/chunk'
import MaxBy from 'lodash/maxBy'
import dateTimeFilter from '@src/filters/datetime'
import { errorNotification } from '@motadata/ui'
import { flattenRecursive } from '@data/recursive'
import { getProductCatalogsApi } from '@modules/asset-management/api/product-catalog-api'

const orderOfFields = [
  'name',
  'asset.url',
  'displayName',
  'assetTypeId',
  'productId',
  'locationId',
  'serverUrl',
  'mobileActivationCode',
  'assignedDepartmentId',
]

export const fieldMap = {
  'asset.url': 'assetUrl',
  assignedDepartmentId: 'departmentName',
  assetTypeId: 'assetTypeName',
  productId: 'productName',
  locationId: 'locationName',
  serverUrl: 'serverUrl',
  mobileActivationCode: 'mobileActivationCode',
}

const inchToPx = (inch) => inch * 96
const pxToInch = (px) => px / 96

const DOTTED_SEGMENT_LENGTH = 6

const WIDTH = 114
const HEIGHT = 114
// @TODO unused funtion
export function prepareAssetForQrCode(assets, dependentData) {
  const productsMap = {}
  const locationsMap = {}
  const departmentMap = {}
  const assetTypeMap = {}
  const locationFlattenedOptions = flattenRecursive(
    dependentData.locationOptions
  )
  locationFlattenedOptions.forEach((location) => {
    locationsMap[location.id] = location.name
  })
  const assetTypeOptionsFlattenedOptions = flattenRecursive(
    dependentData.assetTypes
  )
  assetTypeOptionsFlattenedOptions.forEach((assetType) => {
    assetTypeMap[assetType.id] = assetType.name
  })
  const departmentFlattenedOptions = flattenRecursive(
    dependentData.departmentOptions
  )
  departmentFlattenedOptions.forEach((department) => {
    departmentMap[department.id] = department.name
  })
  let p = Promise.resolve()
  const productIds = assets.map((a) => a.productId).filter(Boolean)
  if (productIds.length) {
    p = getProductCatalogsApi({ ids: productIds }).then(({ items }) => {
      items.forEach((i) => {
        productsMap[i.id] = i.name
      })
    })
  }
  return p.then(() => {
    return assets.map((asset) => {
      const customFieldsTransform = {}
      if (dependentData.datetimeFields) {
        dependentData.datetimeFields.forEach((field) => {
          customFieldsTransform[field.id] = dateTimeFilter(
            asset[field.id],
            undefined,
            field.attributes.allowTime
          )
        })
      }
      if (dependentData.dependentFields) {
        dependentData.dependentFields.forEach((field) => {
          const flattenedTree = flattenRecursive(field.tree)
          customFieldsTransform[field.id] = (
            flattenedTree.find((o) => o.id === asset[field.id]) || {}
          ).name
        })
      }
      return {
        ...asset,
        ...customFieldsTransform,
        ...(asset.productId
          ? { productName: productsMap[asset.productId] }
          : {}),
        ...(asset.assignedDepartmentId
          ? { departmentName: departmentMap[asset.assignedDepartmentId] }
          : {}),
        ...(asset.locationId
          ? { locationName: locationsMap[asset.locationId] }
          : {}),
        ...(asset.assetTypeId
          ? { assetTypeName: assetTypeMap[asset.assetTypeId] }
          : {}),
        ...(dependentData.$modules
          ? {
              assetUrl: `${window.location.origin}${
                dependentData.$router.resolve(
                  dependentData.$modules.getModuleRoute('asset', 'view', {
                    params: {
                      id: asset.id,
                      assetType: dependentData.moduleName,
                    },
                  })
                ).href
              }`,
            }
          : {}),
      }
    })
  })
}

export function generateQrCodeForAsset(asset, fields) {
  // const data = []
  // orderOfFields.forEach((field) => {
  //   if (fields[field]) {
  //     data.push(asset[fieldMap[field] || field])
  //   }
  // })
  // Object.keys(fields).forEach((field) => {
  //   if (orderOfFields.indexOf(field) === -1) {
  //     data.push(asset[fieldMap[field] || field])
  //   }
  // })
  // const qrData = data.filter(Boolean).join('\n')
  const data = {}
  orderOfFields.forEach((field) => {
    if (fields[field]) {
      data[fields[field] || field] = asset[fieldMap[field] || field]
    }
  })
  Object.keys(fields).forEach((field) => {
    if (orderOfFields.indexOf(field) === -1) {
      data[fieldMap[field] || fields[field]] = asset[fieldMap[field] || field]
    }
  })
  const qrData = JSON.stringify(data)
  const svgString = new QrCodeSvg({
    content: qrData,
    padding: 0,
    height: HEIGHT,
    width: WIDTH,
  }).svg()
  return new Promise((resolve) => {
    var canvas = document.createElement('canvas')
    canvas.height = HEIGHT
    canvas.width = WIDTH
    var ctx = canvas.getContext('2d')
    var DOMURL = self.URL || self.webkitURL || self
    var img = new Image()
    var svg = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' })
    var url = DOMURL.createObjectURL(svg)
    img.onload = function () {
      ctx.drawImage(img, 0, 0)
      var png = canvas.toDataURL('image/png')
      resolve(png)
      DOMURL.revokeObjectURL(png)
      canvas = null
    }
    img.src = url
  })
}
export function generateQrCodeForAssetUsingQrData(data) {
  const qrData = typeof data === 'string' ? data : JSON.stringify(data)
  const svgString = new QrCodeSvg({
    content: qrData,
    padding: 0,
    height: HEIGHT,
    width: WIDTH,
  }).svg()
  return new Promise((resolve) => {
    var canvas = document.createElement('canvas')
    canvas.height = HEIGHT
    canvas.width = WIDTH
    var ctx = canvas.getContext('2d')
    var DOMURL = self.URL || self.webkitURL || self
    var img = new Image()
    var svg = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' })
    var url = DOMURL.createObjectURL(svg)
    img.onload = function () {
      ctx.drawImage(img, 0, 0)
      var png = canvas.toDataURL('image/png')
      resolve(png)
      DOMURL.revokeObjectURL(png)
      canvas = null
    }
    img.src = url
  })
}

const dottedLine = (
  doc,
  xFrom,
  yFrom,
  xTo,
  yTo,
  segmentLength = DOTTED_SEGMENT_LENGTH
) => {
  // Calculate line length (c)
  const a = Math.abs(xTo - xFrom)
  const b = Math.abs(yTo - yFrom)
  // eslint-disable-next-line
  const c = Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2))

  // Make sure we have an odd number of line segments (drawn or blank)
  // to fit it nicely
  const fractions = c / segmentLength
  const adjustedSegmentLength =
    Math.floor(fractions) % 2 === 0
      ? c / Math.ceil(fractions)
      : c / Math.floor(fractions)

  // Calculate x, y deltas per segment
  const deltaX = adjustedSegmentLength * (a / c)
  const deltaY = adjustedSegmentLength * (b / c)

  let curX = xFrom
  let curY = yFrom
  while (curX <= xTo && curY <= yTo) {
    doc.line(
      pxToInch(curX),
      pxToInch(curY),
      pxToInch(curX + deltaX),
      pxToInch(curY + deltaY)
    )
    curX += 2 * deltaX
    curY += 2 * deltaY
  }
}

export const imagePositioner = (
  doc,
  images,
  horizontalMargin = 0,
  verticalMargin = 0
) => {
  const pageWidth = inchToPx(doc.internal.pageSize.width)
  const pageHeight =
    inchToPx(doc.internal.pageSize.height) - verticalMargin - verticalMargin
  let startXPosition = horizontalMargin
  let startYPosition = verticalMargin
  let chunkSize = 0
  let startPosition = startXPosition
  const imageWidth = MaxBy(images, 'width').width
  const imageHeight = MaxBy(images, 'height').height
  while (startPosition + imageWidth < pageWidth) {
    startPosition += imageWidth + horizontalMargin
    chunkSize++
  }
  const halfXMargin = horizontalMargin / 2
  const halfYMargin = verticalMargin / 2
  doc.setLineWidth(pxToInch(2))
  doc.setDrawColor(200, 205, 206)
  Chunk(images, chunkSize).forEach((chunk) => {
    if (startYPosition + imageHeight >= pageHeight) {
      doc.addPage()
      doc.setLineWidth(pxToInch(2))
      doc.setDrawColor(200, 205, 206)
      startXPosition = horizontalMargin
      startYPosition = verticalMargin
    }
    chunk.forEach((i, index) => {
      if (index === 0) {
        dottedLine(
          doc,
          startXPosition - halfXMargin,
          startYPosition - halfXMargin,
          startXPosition - halfXMargin,
          startYPosition + imageHeight + halfYMargin
        )
      }
      doc.addImage(
        i.image,
        'png',
        pxToInch(startXPosition),
        pxToInch(startYPosition)
      )
      dottedLine(
        doc,
        startXPosition - halfXMargin,
        startYPosition + imageHeight + halfYMargin,
        startXPosition + imageWidth + (halfXMargin - DOTTED_SEGMENT_LENGTH),
        startYPosition + imageHeight + halfYMargin
      )
      startXPosition += imageWidth + horizontalMargin
      dottedLine(
        doc,
        startXPosition - halfXMargin,
        startYPosition - halfYMargin,
        startXPosition - halfXMargin,
        startYPosition + imageHeight + (halfYMargin - DOTTED_SEGMENT_LENGTH)
      )
    })
    startYPosition += imageHeight + verticalMargin
    startXPosition = horizontalMargin
  })
  return doc
}

export function generatePdf(images, fileName, returnBlob = false) {
  const allImages = images.map((image) => ({
    image,
    width: WIDTH,
    height: HEIGHT,
  }))
  return new Promise((resolve, reject) => {
    import('jspdf')
      .then((Pdf) => {
        Pdf = Pdf.default || Pdf
        const doc = new Pdf('p', 'in', 'a4')
        doc.autoPrint()
        imagePositioner(doc, allImages, 68, 50)
        if (returnBlob) {
          return resolve(doc.output('blob'))
        }
        doc.save(fileName || 'qrcodes.pdf')
        resolve()
      })
      .catch(reject)
  })
}

function getCsv(assets, fields) {
  const headers = []
  const data = []
  orderOfFields.forEach((field) => {
    if (fields[field]) {
      data.push(fieldMap[field] || field)
      headers.push(fields[field])
    }
  })
  Object.keys(fields).forEach((field) => {
    if (orderOfFields.indexOf(field) === -1) {
      data.push(fieldMap[field] || field)
      headers.push(fields[field])
    }
  })
  const rows = assets
    .map((a) => {
      return data
        .map((fieldKey) => (a[fieldKey] ? `"${a[fieldKey]}"` : ''))
        .join(',')
    })
    .join('\n')
  return `${headers.join(',')}\n${rows}`
}

export const generateQrcodeZip = (assets, fields) => {
  if (assets.length === 0) {
    return Promise.reject(
      errorNotification(
        {
          message: 'error message here',
          description: 'error description here',
        },
        'error'
      )
    )
  }
  // showLoader(0, __translate('asset:generatingzipfileofassetbarcodes'))
  let JsZip
  return import('jszip')
    .then((zipModule) => {
      JsZip = zipModule.default || zipModule
      return Promise.all(assets.map((a) => generateQrCodeForAsset(a, fields)))
    })
    .then((images) => generatePdf(images, null, true))
    .then((pdfBlob) => {
      const zip = new JsZip()
      zip.file('qrcodes.pdf', pdfBlob)
      zip.file('assets.csv', getCsv(assets, fields))
      return zip.generateAsync({ type: 'blob' })
    })
    .then((blob) => {
      import('file-saver').then((FS) => FS.saveAs(blob, 'assets-qrcodes.zip'))
    })
}

export const generateQrcodeZipUsingQrData = (data) => {
  if (data.length === 0) {
    return Promise.reject(
      errorNotification(
        {
          message: 'error message here',
          description: 'error description here',
        },
        'error'
      )
    )
  }
  // showLoader(0, __translate('asset:generatingzipfileofassetbarcodes'))
  let JsZip
  return import('jszip')
    .then((zipModule) => {
      JsZip = zipModule.default || zipModule
      return Promise.all(data.map((d) => generateQrCodeForAssetUsingQrData(d)))
    })
    .then((images) => generatePdf(images, null, true))
    .then((pdfBlob) => {
      const zip = new JsZip()
      zip.file('qrcodes.pdf', pdfBlob)
      // zip.file('assets.csv', getCsv(assets, fields))
      return zip.generateAsync({ type: 'blob' })
    })
    .then((blob) => {
      import('file-saver').then((FS) => FS.saveAs(blob, 'assets-qrcodes.zip'))
    })
}
