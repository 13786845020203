<template>
  <FlotoThemeComponent portal="support-portal">
    <ChatProvider @chat-config="isChatEnabled = $event.enabled">
      <ContactOpsProvider portal-mode>
        <MLayout class="mainLayout">
          <Header
            portal-mode
            :pinned="pinned"
            @change="handleChangePinnedMenu"
          />
          <MLayout class="relative contentContainer">
            <NavBar
              v-if="loggedIn"
              :key="$route.fullPath"
              portal-mode
              :pinned="pinned"
            />
            <FlotoShortcutHandler v-if="!vuexLoading" is-portal>
              <MLayout class="mainLayoutContent" :class="{ pinned: pinned }">
                <FlotoLicenseChecker
                  :support-portal-layout-class="
                    !loggedIn ? 'min-h-0' : undefined
                  "
                >
                  <SocketListener :supported-vuex-actions="vuexModuleActions">
                    <FlotoScrollView>
                      <MLayoutContent
                        class="content-layout"
                        :class="{ 'pb-8': isChatEnabled }"
                      >
                        <slot />
                        <SupportChannel socket-path="/chat-server" />
                      </MLayoutContent>
                    </FlotoScrollView>
                  </SocketListener>
                </FlotoLicenseChecker>
              </MLayout>
            </FlotoShortcutHandler>
          </MLayout>
        </MLayout>
      </ContactOpsProvider>
    </ChatProvider>
  </FlotoThemeComponent>
</template>

<script>
import Bus from '@utils/emitter'
import NavBar from '@components/layout/navbar'
import Header from '@components/layout/header'
import {
  PreferenceComputed,
  PreferenceMethods,
} from '@state/modules/preference'
import { TaskMethods } from '@state/modules/task'
import { authComputed, authMethods } from '@state/modules/auth'
import { BrandingComputed } from '@state/modules/branding'
import {
  SupportPortalConfigComputed,
  SupportPortalConfigMethods,
} from '@state/modules/support-portal-config'
import SocketListener from '@components/socket-listener.vue'
import SupportChannel from '@components/chat/support-channel.vue'
import ChatProvider from '@components/chat/chat-provider.vue'
import ContactOpsProvider from '@components/contactOps/contactOps-provider'

import { SsoConfigActions, SsoConfigComputed } from '@state/modules/sso-config'
import { CategoryMethods } from '@state/modules/category'

export default {
  name: 'SupportPortalLayout',
  components: {
    NavBar,
    Header,
    SocketListener,
    SupportChannel,
    ChatProvider,
    ContactOpsProvider,
  },
  data: function () {
    return {
      vuexLoading: true,
      pinned: false,
      isChatEnabled: false,
    }
  },
  computed: {
    ...PreferenceComputed,
    ...authComputed,
    ...BrandingComputed,
    ...SupportPortalConfigComputed,
    ...SsoConfigComputed,
    vuexModuleInitMethods() {
      return [
        'status/request',
        'urgency',
        'impact',
        'priority',
        'location',
        'source',
        'department',
        'category/request',
        'userGroup/technician',
        'userGroup/requester',
        'form/request',
        'form/feedback',
        'timezone',
        ...(this.loggedIn
          ? [
              'status/problem',
              'status/change',
              'status/release',
              'status/asset',
              'status/purchase',
              'status/task',
              'technician',
              'category/problem',
              'category/change',
              'category/release',
              'category/serviceCatalog',
              'form/problem',
              'form/change',
              'form/release',
              'form/asset',
              'form/purchase',
              'assetType',
              'currency',
            ]
          : []),
      ]
    },
    vuexModuleActions() {
      if (this.user && this.user.userType === 'technician') {
        return undefined
      }
      return this.vuexModuleInitMethods
    },
  },
  watch: {
    loggedIn() {
      this.fillVuex()
    },
    companyFavicon: {
      immediate: true,
      handler(newValue) {
        let link = document.querySelector("link[rel*='icon']")
        link.type = 'image/x-icon'
        link.rel = 'shortcut icon'
        link.href = newValue
        link = null
        // document.getElementsByTagName('head')[0].appendChild(link);
      },
    },
    $route(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.handleChangePinnedMenu(false)
      }
    },
  },
  created() {
    this.fetchSsoConfig()
    this.fetchConfigs().then(() => {
      this.fillVuex()
    })
    const logoutHandler = (user) => {
      if (user.loginSource === 'sso_login') {
        if (this.ssoConfig.idpLogoutUrl) {
          return (window.location.href = `${this.ssoConfig.idpLogoutUrl}`)
        } else if (this.ssoConfig.idpLoginUrl) {
          return (window.location.href = `${this.ssoConfig.idpLoginUrl}`)
        }
      }
      if (this.$route.name !== 'support-portal') {
        this.$router.replace(
          this.$modules.getModuleRoute('auth', 'login', {
            query: { redirectFrom: this.$route.fullPath },
          })
        )
      }
    }
    Bus.$on('auth:logout', logoutHandler)
    this.$once('hook:beforeDestroy', () => {
      Bus.$off('auth:logout', logoutHandler)
    })
  },
  mounted() {
    this.changeLoginType('support-portal')
    if (this.loggedIn) {
      this.fetchPreferences().then(() => {
        this.fetchTenantPrefrences()
        this.fetchTaskType()
      })
    } else {
      this.fetchTenantPrefrences()
    }
  },
  methods: {
    ...PreferenceMethods,
    ...SupportPortalConfigMethods,
    ...authMethods,
    ...SsoConfigActions,
    ...CategoryMethods,
    ...TaskMethods,
    fillVuex() {
      if (this.loggedIn || this.allowGuestToCreateIncident) {
        this.vuexModuleInitMethods.forEach((m) => {
          this.$store.dispatch(`${m}/fetch`)
        })
        this.categoryConfigFetch()
        this.vuexLoading = false
      } else {
        this.vuexLoading = false
      }
    },
    handleChangePinnedMenu($event) {
      requestAnimationFrame(() => {
        this.pinned = $event
      })
    },
  },
}
</script>

<style lang="less" scoped>
.mainLayout {
  height: 100vh;
}

.mainLayoutContent {
  padding: @layout-header-height 0 0 0;
  // background-color: var(--neutral-lightest) !important;
  &.pinned {
    padding-left: 50px;
  }
}

.contentContainer {
  min-height: calc(100vh - @layout-header-height);
}

.content-layout {
  min-height: calc(100vh - @layout-header-height);

  @apply pt-3 overflow-x-hidden flex flex-col min-w-0; //pt-6 pl-3 pr-3
}
</style>
